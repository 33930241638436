import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Typing HoCs",
  "subtitle": "Writing type signatures for Higher Order Components",
  "date": "2018-07-13T00:00:00.000Z",
  "layout": "post",
  "draft": false,
  "tags": ["javascript", "typescript", "higher order components", "react"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The process of writing a type signature for a HoC can be daunting at first. In this post, I’ll build an HoC and detail the process of writing a type signature for it. `}</p>
    <p>{`The HoC we’ll develop will be simple; it’ll take a promise, and handle the state-management, providing the inner component with the value, and whether it is loading or not. The usage will look like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`UserProfile`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`loading`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` user`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
  loading `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Often times when writing a type signature for a function, it's useful to say out loud what the function is designed to do at a high level. In this instance, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{` is a function that takes a function which returns a promise, and it itself returns another function which takes a component and returns another component (re-read that sentence a few times if you need to).`}</p>
    <h2>{`Curried functions`}</h2>
    <p>{`Higher order component functions are often `}<em parentName="p">{`curried`}</em>{`, meaning the arguments are applied in separate argument lists. This is a fancy term, but mostly just means that we invoke them like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`as opposed to:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Let’s split up the usage so we can tackle each step separately.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` withPromiseHoc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{` is just a function that returns an hoc (which we’ll worry about typing later). It takes a function which returns a promise. This gives us something to start with:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Promise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`any`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{` returns a function that takes a component and returns another component, The type of that function is: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`(c: ComponentType<any>) => ComponentType<any>`}</code>{`.
`}<em parentName="p"><code parentName="em" {...{
          "className": "language-text"
        }}>{`ComponentType<A>`}</code>{` is a React-specific type that describes any component (sfc or class), where `}<code parentName="em" {...{
          "className": "language-text"
        }}>{`A`}</code>{` is the type of the props it expects`}</em>{`
We can simply replace the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`???`}</code>{` with this type:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Promise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`any`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`c`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` ComponentType`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`any`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` ComponentType`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`any`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Now we’re getting somewhere!`}</p>
    <h3>{`Generic Type Parameters`}</h3>
    <p>{`You’ve probably noticed that we’re using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`any`}</code>{` type frequently. You’ve probably heard that this isn’t desirable, and I’ll show you why.`}</p>
    <p>{`Let’s go back to our original example, where we have a component that renders a user:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`UserProfile`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`loading`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` user`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
  loading `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`user`}</code>{` parameter has `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`name`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`age`}</code>{` properties. Let’s suppose we’re using it like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`Where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fetchUser`}</code>{` returns a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Promise`}</code>{` of a user, which has the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`name`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`age`}</code>{` attributes.`}</p>
    <p>{`Now let’s suppose a colleague has come along and refactored `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fetchUser`}</code>{` to not only fetch a user, but also their preferences. Instead of returning a user, it now returns an object with shape: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  user`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` 'paul'
    age`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`29`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  preferences`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    lang`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` 'en-US'
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Since we’re using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`any`}</code>{`, Typescript can’t make any assumptions about the type of object coming from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`fetchUser()`}</code>{`. Even though our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`UserProfile`}</code>{` expects users, typescript won’t complain, and our colleague will go on thinking that they haven’t broken anything with their change.`}</p>
    <p>{`To guard ourselves against this pitfall, we can use a type parameter to align the return type of the promise with the prop types of the inner component. You can name these however you want, but a common pattern is to use a single uppercase character:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`type WithPromiseContext`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  loading`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` boolean`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` withPromise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Promise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`c`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` ComponentType`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`WithPromiseContext`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`A`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` ComponentType`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`any`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Notice how the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` is used in both the type of the promise `}<em parentName="p">{`and`}</em>{` the wrapped component.
The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`WithPromiseContext<A>`}</code>{` is just a type that defines the props that the inner component will receive.`}</p>
    <h2>{`Passing Props Through`}</h2>
    <p>{`You may have noticed that our HoC still returns a type of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ComponentType<any>`}</code>{`. We haven’t defined what props the wrapped component takes. This can get tricky, since the props of the wrapped component won’t necessarily be the same as the inner component. In fact, in our usage, the wrapped component doesn’t have any props! This makes sense if we consider the usage:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`UserProfile`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`loading`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` user`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
  loading `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` WrappedProfile `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`WrappedProfile`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`WrappedProfile`}</code>{` doesn’t take any props, so its type is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ComponentType<{}>`}</code>{`. This won’t be the case for every usage, so let’s tweak our example a bit.`}</p>
    <p>{`Let’s add a prop to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`UserProfile`}</code>{` which isn’t supplied by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{` . This prop will be a boolean that switches on a “dark” css theme:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`type UserProfileProps `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  loading`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` boolean`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` string`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` number`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  dark`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` boolean
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ComponentType`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`UserProfileProps`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`loading`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` user`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` dark`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{`
  loading `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`loading`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`className`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`dark `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'dark'`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'light'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre>{`
      `}</div>
    <p>{`Most HoCs pass on props they receive, so let’s assume `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{` does. In this case, we’ll want the wrapped component to indicate it needs to be supplied a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dark`}</code>{` prop, but `}<em parentName="p">{`not`}</em>{` the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`loading`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`user`}</code>{` props, since they’ll be supplied by `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{`. The usage will look like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` WrappedComponent `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fetchUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`WrappedComponent`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token boolean"
              }}>{`true`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span></code></pre>{`
      `}</div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`WrappedComponent`}</code>{`’s type is therefore `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ComponentType<{dark: boolean}>`}</code>{`.
The tricky thing to remember is that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{` can use a component with any number of other props! What we need is a way to say that `}<em parentName="p">{`the props of the wrapped component should contain all the props of the inner component, minus the ‘loading’ and ‘user’ props`}</em>{`.  `}</p>
    <p>{`Thanks to the work that has gone on in `}<a parentName="p" {...{
        "href": "https://github.com/Microsoft/TypeScript/issues/12215#issuecomment-377567046"
      }}>{`this`}</a>{` GitHub issue, we can realize a type which does this generically!`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`type Omit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`K`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`keyof`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Pick`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Exclude`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`keyof `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`T`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`K`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span></code></pre>{`
      `}</div>
    <p>{`This type takes any two types (such that the second extends the first) and returns another type that includes everything in the first, that isn’t in the second.`}</p>
    <p>{`We just need to add another type parameter which will indicate the props of the inner component: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` withPromise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Promise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`c`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` ComponentType`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`WithPromiseContext`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` ComponentType`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`any`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`However, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{` still needs the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`loading`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dark`}</code>{` props from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`WithPromiseContext<A>`}</code>{`, so we add that constraint to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` withPromise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`WithPromiseContext`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Promise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`c`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` ComponentType`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`WithPromiseContext`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` ComponentType`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`any`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Ok, so now that we have a type  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{` which has everything in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`WithPromiseContext<A>`}</code>{` , and potentially more, we need to specify that the returned wrapped component’s props should have everything in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{` which is not in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`WithPromiseContext<A>`}</code>{`. This is where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Omit`}</code>{` becomes handy. This type we’re looking for can be expressed as: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Omit<B, keyof WithPromiseContext<A>>`}</code>{`. This is the type of the props expected for the returned wrapped component, so let’s add it to the signature:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` withPromise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`WithPromiseContext`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Promise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`c`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` ComponentType`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span>{`WithPromiseContext`}<span parentName="span" {...{
              "className": "token operator"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token constant"
            }}>{`B`}</span><span parentName="span" {...{
              "className": "token operator"
            }}>{`>>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` 
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` ComponentType`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Omit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` keyof WithPromiseContext`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>>>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Now when we use  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{` with our updated component, the type of the returned component will include the props that aren’t supplied via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{`:  `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "jsx"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-jsx"
      }}><code parentName="pre" {...{
          "className": "language-jsx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` WrappedUserProfile `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` withPromise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`User`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` UserProfileProps`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`getUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`WrappedUserProfile`}</span></span>{` `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// fails since it doesn't have a "dark" prop`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`WrappedUserProfile`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`true`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// fails since "dark" is not a boolean`}</span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span><span parentName="span" {...{
                "className": "token class-name"
              }}>{`WrappedUserProfile`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`dark`}</span><span parentName="span" {...{
              "className": "token script language-javascript"
            }}><span parentName="span" {...{
                "className": "token script-punctuation punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "token boolean"
              }}>{`true`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`/>`}</span></span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// succeeds`}</span></code></pre>{`
      `}</div>
    <h2>{`Inferring Wrapped Type props`}</h2>
    <p>{`You may have noticed that when you use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{` to wrap a component that has additional props, you must specify the type parameters:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`withPromise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`User`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` UserProfileProps`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`getUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`If you don’t specify them ahead of time, you’ll get an error:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`getUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// fails with:`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Property 'dark' is missing in type: 'WithPromiseContext<User>'`}</span></code></pre>{`
      `}</div>
    <p>{`This has to do with the fact that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{` is curried. Typescript first examines the expression:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`getUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre>{`
      `}</div>
    <p>{`to determine its type. Since we didn’t include any type parameters, Typescript has to make a guess at what the types are. There’s simply no way for Typescript to know the prop types of the inner component, since we haven’t supplied it yet. The only thing that Typescript knows for sure is that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{` will at least have all the props in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`WithPromiseContext<A>`}</code>{`, so it simply uses the type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`WithPromiseContext<A>`}</code>{`.
It’s easier to see if the curried invocation is split up:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` withUserHoc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`getUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// here, withUserHoc doesn't have the inner component's props type,`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//   so it sticks with 'WithPromiseContext<A>'`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`withUserHoc`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// UserProfile has an extra "dark" property, which isn't in 'WithPromiseContext<A>', so we get an error`}</span></code></pre>{`
      `}</div>
    <p>{`We’d like for Typescript to `}<em parentName="p">{`infer`}</em>{` the props of the wrapped component. To fix this, we’ll need to delay the resolution of the second type parameter until the last argument list is provided.`}</p>
    <p>{`To aid with clarity, we’ll come up with a type definition, specifically for an HoC which can remove props from decorated components. Let’s call it `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`InferableHOC`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`interface`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`InferableHOC`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`ProvidedProps`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extends`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ProvidedProps`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    c`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ComponentType`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` React`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ComponentType`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Omit`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`B`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` keyof ProvidedProps`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p><em parentName="p">{`In Typescript, if we want to declare the type of a function that has a type parameter, we must use an interface with a single method.`}</em></p>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`InferableHOC`}</code>{` takes a component with props `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{`,  and returns a component with props that are in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`A`}</code>{` that are not in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ProvidedProps`}</code>{`. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ProvidedProps`}</code>{` represents the props that are provided via our HoC.`}</p>
    <p>{`Since `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`B`}</code>{` now solely exists in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`InferableHOC`}</code>{` type, we remove it from the signature of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`withPromise`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` withPromise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`f`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` Promise`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  InferableHOC`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`WithPromiseContext`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`A`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre>{`
      `}</div>
    <p>{`Now we can have typescript automatically infer the types for us, without having to specify the props manually:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}>{`
      `}<pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` WrappedUserProfile `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`withPromise`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`getUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`UserProfile`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`WrappedUserProfile `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// fails since it doesn't have a "dark" prop`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`WrappedUserProfile dark`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"true"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// fails since "dark" is not a boolean`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`WrappedUserProfile dark`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// succeeds`}</span></code></pre>{`
      `}</div>
    <p>{`Of course, you could just ignore all this headache and write render props, which are far easier to write type signatures for :D`}</p>
    <p><em parentName="p">{`Special thanks to the smart people writing the `}<a parentName="em" {...{
          "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/recompose"
        }}>{`recompose type definitions`}</a>{`!`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      